import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import * as Icon from 'react-bootstrap-icons';
import Badge from 'react-bootstrap/Badge';
import { useDispatch, useSelector } from 'react-redux'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Dropdown from 'react-bootstrap/Dropdown'
import {clearCart, setProducts, setIsLoading, removeProduct, searchProducts, setProps, setCarrito, setCliente} from '../store/cart'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import '../assets/css/custom.css';
import { useState } from 'react';
import {setIsSearch} from '../store/cart';
import Carrito from './Carrito';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios'
import { useEffect } from "react";
import { getCategorias } from "../store/cart";


function OffcanvasExample() {
    //console.log(process.env.REACT_APP_BASE_URL);
    const {cart} = useSelector(state => state.cartSlice);
    const {cliente} = useSelector(state => state.cartSlice);
    //const {subtotal} = useSelector(state => state.cartSlice);
    const {props} = useSelector(state => state.cartSlice);
    const dispatch = useDispatch()
    const [desc, setDesc] = useState('');
    //const dispatch = useDispatch()

    useEffect(() => {

        dispatch(getCategorias())

    }, [dispatch]) 

    const {categorias} = useSelector(state => state.cartSlice);


    var carrito = {}
    var client = {}
    const local_carrito = localStorage.getItem("carrito")
    if(local_carrito !== "undefined"){
      carrito = JSON.parse(local_carrito)
    }
    else{
      carrito = cart
    }

    const local_cliente = localStorage.getItem("cliente")
    if(local_cliente !== "undefined" && local_cliente !== "null"){
      client = JSON.parse(local_cliente)
      //client.opciones_pago = ["Mercado Pago"]
      //localStorage.setItem("cliente",JSON.stringify(client))
    }
    //carrito.map(item => subtotal += item.precio)
    let subtotal = 0;
    let cartItems = 0;
    if(carrito != null){
      carrito.cart.forEach(function(item){
        //console.log(item)
        let precio = client != null && client.lista == 2 && item.precio2 > 0?item.precio2:item.precio
        subtotal+=(item.q*precio)
        cartItems+=(item.q)
      })
    }


    //console.log(subtotal);
    //state.subtotal = subtotal

    var {isLoaded} = useSelector(state => state.cartSlice);
    //console.log(isLoaded)
    //console.log(carrito)
    if(!isLoaded && carrito !== null){
       dispatch(setCarrito(carrito))
       dispatch(setCliente(carrito.cliente))
       //console.log(carrito)
       //console.log(cliente)
    }
    if(!isLoaded && client !== null){
      dispatch(setCliente(client))
      //console.log(carrito)
      //console.log(client)
    }
    //

    const [show, setShow] = useState(false);
    const [register, setRegister] = useState(false);

    const handleClose = () => setShow(false);
    const handleCloseRegister = () => setRegister(false);
    const handleShow = () => setShow(true);
    const handleShowRegister = () => setRegister(true);


    const handleLogin = (event) => {
      event.preventDefault();
      var params = {
        usuario: event.target.usuario.value,
        clave: event.target.clave.value
      }
      //dispatch(setIsLoading(true));
        const url = process.env.REACT_APP_BASE_URL+'/dorla/api/v1/getClienteLogin';
        axios
        .post(url,params)
        .then((response) => {
          if(response.data.id !== undefined){
            //console.log(response.data.id)
            dispatch(setCliente(response.data))
            handleClose()
          }

            //dispatch(setCliente(response.data))
        }).catch((error) => console.log(error))
    }

    const handleRegister = (event) => {
      event.preventDefault();
      var params = {

        razon_social: event.target.nombre.value,
        cuit: event.target.cuit.value,
        iva: event.target.iva.value,
        email: event.target.email.value,
        telefono: event.target.telefono.value,
        domicilio: event.target.domicilio.value,
        provincia: event.target.provincia.value,
        localidad: event.target.localidad.value,
        codigo_postal: event.target.codigo_postal.value
      }
      console.log(params)
      //dispatch(setIsLoading(true));
        const url = process.env.REACT_APP_BASE_URL+'/dorla/api/v1/setCliente';
        axios
        .post(url,JSON.stringify(params))
        .then((response) => {
          event.target.reset();
          setRegister(false);

          confirmAlert({
            message: "Cliente Nº "+response.data+" creado con éxito. Utilice su Nº de CUIT y Nº de cliente para ingresar.",
            buttons: [
              {
                label: 'Aceptar',
                onClick: () => {}
              }
            ],
            overlayClassName : "custom-alert"
          })
          console.log(response)
        }).catch((error) => {console.log(error)})
    }

    const handleLogout = () => {
      var c = {
        id: 0,
        nombre : ""
      }
      dispatch(setCliente(c))
    }

    function getArticulosCategoria(categoria_id){
      //event.persist();
      
      const categoria = categoria_id
      dispatch(setIsLoading(true));
      const data = {
        "categoria" : categoria
      }
      const url = process.env.REACT_APP_BASE_URL+'/dorla/api/v1/getArticulosCategoria';
      axios
      .post(url,data)
      .then((response) => {
        //console.log(response)
        dispatch(setProducts(response.data))
        window.scrollTo(0, 0)
        dispatch(setIsLoading(false));
        dispatch(setIsSearch(true))
      })

    }

    function confirmClear(){
      confirmAlert({
        message: 'Está seguro que desea vaciar el carrito?',
        buttons: [
          {
            label: 'Si',
            onClick: () => {dispatch(clearCart())}
          },
          {
            label: 'No',
            onClick: () => {}
          }
        ],
        overlayClassName : "custom-alert"
    });
    }

    const handleChange = event => {
      setDesc(event.target.value);
      //console.log('value is:', event.target.value);
    };
    const handleKeyUp = event => {
      event.preventDefault();
      if(event.keyCode === 13){
        var args = {
          index: 0,
          desc : desc,
          limit : 100,
          categoria: 0
        }
        dispatch(setProps({args}))
        console.log(args)
        dispatch(searchProducts({args}))
        dispatch(setIsSearch(true))
      }
    }
    const handleSearch = event => {
      event.preventDefault();
      var args = {
        index: 0,
        desc : desc,
        limit : 100,
        categoria: 0
      }
      dispatch(setProps({args}))
      //console.log(args)
      dispatch(searchProducts({args}))
      dispatch(setIsSearch(true))
    };
    const handleChangeCuit = event => {

    }


  return (
    <div>
    <Navbar fixed="top" bg="light" expand="false" className="mb-3 pl-2">
        
          <Container fluid>
            <Navbar.Brand href="/"><img
                    src="https://cloromil.com.ar/dorla/img/brand.png"
                    height="30"
                    className="d-inline-block align-top"
                    alt="React Bootstrap logo"
                    />
            </Navbar.Brand>
            <Col className="ms-3 d-none d-lg-block">
  <Dropdown>
    <Dropdown.Toggle variant="light" id="dropdown-basic">
      Categorias
    </Dropdown.Toggle>

    <Dropdown.Menu style={{ overflowY: 'scroll', maxHeight: '200px' }}>
      {categorias.map(c => (
        <Dropdown.Item key={c.id} eventKey={c.id} onClick={() => getArticulosCategoria(c.id)}>
          {c.nombre}
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  </Dropdown>
</Col>

            <Col className="d-none d-lg-block">
              <InputGroup style={{ width: '600px', aling: 'right' }}>
                    <FormControl 
                    id="searchBox"  
                    placeholder="Buscar" 
                    aria-label="Buscar" 
                    aria-describedby="basic-addon2"
                    name="search"
                    onChange={handleChange}
                    onKeyUp={handleKeyUp}
                    value={desc}
                    />
                    <Button variant="secondary" id="button-addon2" onClick={handleSearch}>
                    Buscar
                    </Button>
                </InputGroup>
            </Col>    
                <Col className="ms-3 d-flex align-end" md={3} xl={3}>
                  <Navbar.Toggle variant="secondary" aria-controls={`offcanvasNavbar-expand-false`} className="text-bold d-inline-flex">
                      <Icon.Cart /><Badge>{cartItems}</Badge> <p className="d-none d-lg-block">  <strong style={{ color: 'black' }}>{subtotal.toLocaleString('es-AR', { 
                        style           : 'currency',
                        currency        : 'ARS',
                        currencyDisplay : 'symbol',
                        useGrouping     : true
                      })}</strong></p>
                  </Navbar.Toggle>
                  <Dropdown className="ms-3">
                    <Dropdown.Toggle variant={cliente.id !== 0?"success":"danger"} id="dropdown-basic">
                    <Icon.Person />
                    </Dropdown.Toggle>
                      {
                        cliente.id !== 0?<Dropdown.Menu placement="start">
                        <Dropdown.Item>Mis Pedidos</Dropdown.Item>
                        <Dropdown.Item onClick={handleLogout}>Salir</Dropdown.Item>
                      </Dropdown.Menu>:<Dropdown.Menu>
                        <Dropdown.Item onClick={handleShow} >Ingreso Clientes</Dropdown.Item>
                        <Dropdown.Item onClick={handleShowRegister} >Registrarse</Dropdown.Item>
                      </Dropdown.Menu>
                      }
                    
                  </Dropdown>
                </Col>
                <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-false`}
              aria-labelledby={`offcanvasNavbarLabel-expand-false`}
              placement="end">
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-false}`}>
                  Carrito de Compras
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body style={{overflowY: 'hidden', overflowX: 'hidden'}} >  
                <div style={{ height: '55vh', overflowY: 'scroll', overflowX: 'hidden' }} className="p-3">             
                      <Carrito />
                  </div>
                   <div className="p-3">
              <hr/>
                    <Row >
                      <Col>SUBTOTAL</Col>
                      <Col className="text-end">{subtotal.toLocaleString('es-AR', { 
                  style           : 'currency',
                  currency        : 'ARS',
                  currencyDisplay : 'symbol',
                  useGrouping     : true
                })}</Col>
                      
                    </Row>
                    <hr/>
                    <Row className="mt-2">
                      <Button variant="secondary" onClick={() => confirmClear()}>VACIAR CARRITO</Button>
                    </Row>
                    <Row className="mt-2">
                      <Button href="/confirmar">FINALIZAR COMPRA</Button>
                    </Row>
              </div> 
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>

          <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Ingreso Clientes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form onSubmit={handleLogin}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Usuario</Form.Label>
        <Form.Control type="text" placeholder="Ingrese su usuario" name="usuario" />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Clave</Form.Label>
        <Form.Control type="password" placeholder="Ingrese su contraseña" name="clave" />
      </Form.Group>
      <div align="center">
        <Button variant="primary" type="submit">
          Ingresar
        </Button>
      </div>
    </Form>
        </Modal.Body>
      </Modal>


      <Modal show={register} onHide={handleCloseRegister}>
        <Modal.Header closeButton>
          <Modal.Title>Registro Clientes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form  onSubmit={handleRegister}>
                  <Row>
                    <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Apellido y Nombre o Razón Social</Form.Label>
                        <Form.Control type="text" placeholder="Apellido y Nombre o Razón Social" name="nombre" required />
                    </Form.Group>
                    </Col>
                  </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>DNI / Cuit</Form.Label>
                                <Form.Control type="text" placeholder="DNI o C.U.I.T." name="cuit" required onKeyUp={handleChangeCuit} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Condición IVA</Form.Label>
                                    <Form.Select aria-label="Default select example" name="iva">
                                        <option value="1">Consumidor Final</option>
                                        <option value="2">Responsable Inscripto</option>
                                        <option value="3">Responsable Monotributo</option>
                                        <option value="4">Exento</option>
                                    </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>E-Mail</Form.Label>
                        <Form.Control type="email" placeholder="E-Mail" name="email" required />
                        <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Domicilio</Form.Label>
                        <Form.Control type="text" placeholder="Calle y Nro" name="domicilio" required />
                    </Form.Group><Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Provincia</Form.Label>
                                    <Form.Select aria-label="Default select example" name="provincia">
                                        <option value="Santa Fe">Santa Fe</option>
                                    </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Localidad</Form.Label>
                                    <Form.Select aria-label="Default select example" name="localidad">
                                        <option value="San Lorenzo">San Lorenzo</option>
                                        <option value="Puerto San Martín">Puerto San Martín</option>
                                        <option value="Capitán Bermúdez">Capitán Bermúdez</option>
                                        <option value="Ricardone">Ricardone</option>
                                        <option value="Fray Luis Beltrán">Fray Luis Beltrán</option>
                                    </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Código Postal</Form.Label>
                                <Form.Control type="text" placeholder="Código Postal" required name="codigo_postal" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Teléfono</Form.Label>
                                <Form.Control type="text" placeholder="Teléfono" name="telefono" required />
                            </Form.Group>
                        </Col>
                    </Row>
                    <div align="center">
        <Button variant="primary" type="submit">
          Registrarse
        </Button>
      </div>
                </Form>
        </Modal.Body>
      </Modal>


    </Navbar>
      <div bg="light" className="d-md-none d-xl-none d-xs-flex d-sm-flex mt-5 pt-3 mb-3 pl-2">
        <Row className="d-inline-flex">
        <Col className="ms-3">
                <Dropdown>
                  <Dropdown.Toggle variant="ligth" id="dropdown-basic">
                    Categorias
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{overflowY: 'scroll', maxHeight: '200px'}}>
                  {categorias.map(c => <Dropdown.Item key={c.id} eventKey={c.id} onClick={getArticulosCategoria}>{c.nombre}</Dropdown.Item>)
                        }
                  </Dropdown.Menu>
                </Dropdown>
                </Col>
                <Col>
              <InputGroup style={{ width: '200px', aling: 'right' }}>
                    <FormControl 
                    id="searchBox"  
                    placeholder="Buscar" 
                    aria-label="Buscar" 
                    aria-describedby="basic-addon2"
                    name="search"
                    onChange={handleChange}
                    onKeyUp={handleKeyUp}
                    value={desc}
                    />
                    <Button variant="secondary" id="button-addon2" onClick={handleSearch}>
                    Buscar
                    </Button>
                </InputGroup>
            </Col>    
        </Row>
      
      </div>
    </div>



  );
}

export default OffcanvasExample;