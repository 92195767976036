import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.min.css'
import Carrito from './Carrito'
import {clearCart, removeProduct, searchProducts, setProps, setEnviado, setCarrito, setIsLoading,setProducts,setCliente,setDatosPedido,setStatus} from '../store/cart'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Card from 'react-bootstrap/Card';
//import { mercadopago } from "mercadopago";
import navigate from 'react';
import Alert from 'react-bootstrap/Alert';

export default function ConfirmarCompra(){

    
    const {cart,enviado,cliente} = useSelector(state => state.cartSlice);
    const dispatch = useDispatch();
    const {datos_pedido} = useSelector(state => state.cartSlice)
    const logo = require('../assets/img/logo.png'); // with require
    const [showAlert, setShowAlert] = useState(false); // Nuevo estado para manejar la visibilidad del Alert
    const [alertMessage, setAlertMessage] = useState(''); // Nuevo estado para el mensaje de la alerta
    let subtotal = 0;
    cart.forEach(function(item){
      console.log(item)
      let precio = cliente.lista == 2 && item.precio2 > 0?item.precio2:item.precio
      subtotal+=(item.q*precio)
    })
    let mensaje = "";
    const carrito = {
        "subtotal" : subtotal,"cart": cart,"cliente": cliente.id
    }

    if(enviado && cart.length === 0){
        return(
            <div className="mt-5 pt-3">
            <Card className="text-center mx-5">
                <Card.Img className="mx-auto" style={{width: '200px'}} variant="top-center" src={logo} />
                <Card.Body>
                    <Card.Text>
                    <Alert variant="success">{alertMessage}</Alert>
                    </Card.Text>
                    <Button variant="primary" onClick={seguirComprando}>Seguir Comprando</Button>
                </Card.Body>
            </Card>
            </div>
        )
    }
    else if(!enviado && cart.length > 0){
        return (
            <div className="mt-5 pt-3">
              <Row className="mx-5">
                  <h3>CONFIRMAR COMPRA</h3>
              </Row>
              <Row className="mx-5">
                  <Col>
                      <Row> <h4>CARRITO DE COMPRAS</h4></Row>
                      <Row style={{maxHeight: '50vh', overflowY: 'scroll'}}>
                          <Carrito/>
                      </Row>
                      <Row className="px-5 pt-5">
                          <Col>SUBTOTAL</Col>
                          <Col className="text-end">{subtotal.toLocaleString('es-AR', { 
                              style           : 'currency',
                              currency        : 'ARS',
                              currencyDisplay : 'symbol',
                              useGrouping     : true
                          })}</Col>
                      </Row>
                  </Col>
                  <Col className="ml-1">
                  <Form onSubmit={(event) => confirmarCompra(event,carrito,dispatch,setShowAlert,setAlertMessage)}>
                      <Row>
                          <Col>
                              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                  <Form.Label>Observaciones</Form.Label>
                                  <Form.Control as="textarea" rows={3} name="observaciones" />
                              </Form.Group>
                          </Col>
                      </Row>
                      <Row>
                          <Col>
                          <Form.Group>
                                  <Form.Label>Medio de Pago</Form.Label>
                                      <Form.Select aria-label="Default select example" name="pago">
                                          {
                                              cliente.opciones_pago.map(function(item){
                                                  return (
                                                      <option value={item.value}>{item.name}</option>
                                                  )
                                              })
                                          }
                                      </Form.Select>
                              </Form.Group>
                          </Col>
                      </Row>
                      <Row>
                          <Col>
                              <Form.Label>Forma de Entrega</Form.Label>
                              <Form.Select aria-label="Default select example" name="envio">
                                          <option value="1">Retiro en Depósito</option>
                                          <option value="2">Envío a Domicilio</option>
                                      </Form.Select>       
                          </Col>
                      </Row>
                      <Row className="px-5 pt-3">
                          <Button variant="primary" type="submit">
                              Confirmar Compra
                          </Button>
                      </Row>
                      </Form>
                  </Col>
              </Row>
              <Row>
                <Col className="m-5">
                    {showAlert && <Alert variant="warning">{alertMessage}</Alert>}
                </Col>
              </Row>
          </div>
      )
    }
    else{
        return(
            <div className="mt-5 pt-3 mx-5">
            <Card className="text-center">
                <Card.Img className="mx-auto" style={{width: '200px'}} variant="top-center" src={logo} />
                <Card.Body>
                    <Card.Text>
                    <Alert variant="warning">El carrito está vacío.</Alert>
                    </Card.Text>
                    <Button variant="primary" onClick={seguirComprando}>Ver Productos</Button>
                </Card.Body>
            </Card>

            </div>
        )
    }
    
}

function seguirComprando(){
    window.location.href = "/products"
}

function handleChangeCuit(event){
    if (event.keyCode === 13) {
        //setState({ cuit: event.target.value });
        var params = {cuit: event.target.value};
        const url = process.env.REACT_APP_BASE_URL + '/dorla/api/v1/getCliente';
        axios
        .post(url,params)
        .then((response) => {
            console.log(response)
            //dispatch(setCliente(response.data))
        }).catch((error) => console.log(error))
    }
}

export const confirmarCompra = (event,cart,dispatch,setShowAlert,setAlertMessage) => {
    event.preventDefault()
    const datos_pedido = {
        observaciones: event.target.observaciones.value,
        pago: event.target.pago.value,
        envio: event.target.envio.value,
    }
    cart.datos_pedido = datos_pedido
    
    dispatch(setCarrito(cart))
    if(event.target.pago.value === "mercado_pago"){
                
        const url = process.env.REACT_APP_BASE_URL + "/dorla/api/v1/mercado_pago";
        axios.post(url,cart)
            .then((response) => {
                window.location.href = response.data.url
                //dispatch(clearCart())
            })
            .catch((error) => console.log(error))
        
        //window.location.href = "/pedido_confirmado"

    }
    else{
       
        const url = process.env.REACT_APP_BASE_URL + '/dorla/api/v1/confirmarCompra';
        axios
        .post(url,cart)
        .then((response) => {
            console.log(response)
            if (response.data.error != undefined && !response.data.error) {
                dispatch(clearCart());
                dispatch(setEnviado());
                setAlertMessage(response.data.msg);
            }
            else {
                if(response.data.msg != undefined){
                    dispatch(setStatus(response.data)); // Actualizar el estado del mensaje
                    setShowAlert(true)
                    setAlertMessage('Error. '+response.data.msg);
                    //alert('Hubo un error: ' + response.data);
                }
                else{
                    dispatch(setStatus(response.data)); // Actualizar el estado del mensaje
                    setShowAlert(true)
                    setAlertMessage('Error. La respuseta del servidor no se pudo interpretar.'+response.data);
                }
            }
        })
        .catch((error) => console.log(error))}
    }

