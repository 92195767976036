import React from 'react';
import Productos from '../components/Productos';
import OffCanvasExample from '../components/OffCanvas';
import Footer from '../components/Footer';


function Products(props){    
    return (
        <div>
        <OffCanvasExample />
        <Productos />
        <Footer />
      </div>
    );
}

export default Products;