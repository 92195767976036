
import ConfirmarCompra from "../components/ConfirmarCompra";
import OffcanvasExample from '../components/OffCanvas';
import Footer from "../components/Footer";
import Productos from '../components/Productos';
import { useSelector, useDispatch } from 'react-redux'

function Confirmar(){
    const {cart} = useSelector(state => state.cartSlice);
    const {list} = useSelector(state => state.cartSlice);
    const {isSearch} = useSelector(state => state.cartSlice);

    return (
        <div>
            <OffcanvasExample />
            {
            list.length > 0 && isSearch?<Productos />:
                <div>
                    <ConfirmarCompra />
                </div>
            }
            <Footer />
        </div>
    );
}

export default Confirmar;