
import Row from "react-bootstrap/esm/Row"
import Col from "react-bootstrap/esm/Col"
import NavLink from "react-bootstrap/esm/NavLink"


function Footer(){
        let footer = {
            position: "fixed",
            left: "0",
            bottom: "0",
            height: "80px",
            width: "100%"
        }
        let regularDiv = {
          backgroundColor: "white",
          borderTop: "1px solid grey",
          textAlign: "center",
          padding: "10px",
          position: "fixed",
          left: "0",
          bottom: "0",
          height: "80px",
          width: "100%",
      }
      let wrapperDiv = {
        display: 'block',
        //padding: '40px',
        height: '80px',
        width: '100%',
      }
          return (
                <Row className="mt-3 mb-3" style={{display:"fixed", bottom:0}}>
                      <Col>
                      </Col>
                      <Col><Row>
                                <div align="center" style={{display : "inline"}}>
                                    <a href="#">nosotros</a> | <a href="/products">productos</a> | <a href="#">contacto</a> 
                                </div>
                            </Row>

                            <Row>
                                <div align="center">
                                    Av. Dorrego 749, San Lorenzo (Sta. Fe)
                                </div>
                            </Row></Col>
                      <Col>
                      <div align="right" style={{top: "85vh", marginRight:"10px"}}>
                            <a style={{marginLeft: "auto"}} rel="noreferrer" href="https://api.whatsapp.com/send?phone=543476367935" target="_blank">
                                <img src={require('../assets/img/btn_wapp.png')} />
                            </a>
                        </div>
                      </Col>
                      </Row>
/*
            <div>
              <div style={{height: "200vh", zIndex: 9999}}>
                  <div style={wrapperDiv} />
                  <div style={regularDiv}>

                  

                        
            </div>
                  </div>

              </div>

  */          
          )
      }

export default Footer