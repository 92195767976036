import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getAllProducts, setIsLoading, addProduct, loadMoreProducts, setProps } from '../store/cart'
import { useDispatch, useSelector } from 'react-redux'
import ReactLoading from 'react-loading';
import { useState } from 'react';

const Productos = () => {
  const { list } = useSelector(state => state.cartSlice);
  const { isLoading } = useSelector(state => state.cartSlice);
  const { props } = useSelector(state => state.cartSlice);
  const { isAdding } = useSelector(state => state.cartSlice);
  const dispatch = useDispatch();
  const logo = require('../assets/img/logo.png'); // with require
  const cliente = JSON.parse(localStorage.getItem("cliente"));
  const [isFetching, setIsFetching] = useState(false);

  const incrementInputValue = (id) => {
    setInputValues(prevValues => ({
      ...prevValues,
      [id]: (prevValues[id] || 1) + 1
    }));
  };

  const decrementInputValue = (id) => {
    setInputValues(prevValues => ({
      ...prevValues,
      [id]: Math.max((prevValues[id] || 1) - 1, 1)
    }));
  };

  useEffect(() => {
    if (list.length === 0) {
      dispatch(setIsLoading(true));
      dispatch(getAllProducts())
      dispatch(setIsLoading(false));
    }
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    if (isFetching) {
      console.log(props);
      // dispatch(loadMoreProducts(props))
    }
  }, [isFetching, dispatch]);

  function handleScroll() {
    const scroll = window.innerHeight + document.documentElement.scrollTop - document.scrollingElement.scrollHeight;
    const prop = {
      categoria: props.categoria,
      desc: props.desc,
      index: props.index,
      limit: props.limit,
      scroll: scroll
    };
    dispatch(setProps(prop));
    if (scroll === 0) {
      setIsFetching(true);
    } else {
      setIsFetching(false);
    }
  }

  const [inputValues, setInputValues] = useState({});

  return (
    <div>
      {isLoading ?
        <Container className="mt-5 pt-5">
          <ReactLoading className="m-auto mt-5 pt-5" type="spinningBubbles" color="#999999" height={'10%'} width={'10%'} />
        </Container> :
        <Container className="mt-5 pt-5">
          <Row>
            {list.map(post =>
              <Col id={post.id} className={cliente != null && cliente.lista == 2 && post.precio2 == 0 ? "d-none" : ""} sm={12} xs={12} lg={3} md={3} xl={3}>
                <Card key={post.id} className="mt-1 mx-auto">
                  <div style={{ minHeight: '250px', maxHeight: '250px' }}>
                    <Card.Img style={{ height: '250px' }} variant="top" src={post.imagen !== '' ? `data:image/jpeg;base64,${post.imagen }` : logo} />
                  </div>
                  <Card.Body className="text-center">
                    <Card.Title className="text-secondary" style={{ height: '70px', fontSize: '18px' }}>{post.id} - {post.descripcion}</Card.Title>
                    <Card.Text className="h4">${cliente != null && cliente.lista == 2 && post.precio2 > 0 ? post.precio2 : post.precio}</Card.Text>
                    <div className="d-inline d-inline-flex">
                      <input
                        style={{ width: '60px', marginRight: '5px' }}
                        className="form-control mr-2"
                        type="number"
                        value={inputValues[post.id] || 1}
                        onChange={(e) => {
                          const newValue = parseInt(e.target.value, 10);
                          setInputValues(prevValues => ({
                            ...prevValues,
                            [post.id]: isNaN(newValue) ? 1 : Math.max(newValue, 1)
                          }));
                        }}
                      />
                      <Button className="ml-auto mr-auto" variant="primary" onClick={() => dispatch(addProduct({post,cantidad: inputValues[post.id] || 1}))}>Agregar al Carrito</Button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            )}
          </Row>
        </Container>}
      {isAdding ? <ReactLoading className="m-auto mt-3" type="spinningBubbles" color="#999999" height={'10%'} width={'10%'} />
        : <div></div>}
    </div>
  );
};

export default Productos;
