import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import {clearCart, setEnviado} from '../store/cart'
import Alert from 'react-bootstrap/Alert';
const PedidoConfirmado = () => {

    const {cart,datos_pedido,cliente,subtotal,enviado} = useSelector(state => state.cartSlice)
    const dispatch = useDispatch();

    console.log(enviado)
    if(enviado){
        return (
            <Container className="mt-5 pt-5">
                <Row>
                    El carrito está vacío o ya se envió el pedido.
                </Row>
            </Container>
        )
    }
    else{
        if(cart.length > 0){
            const data = {
                cart : cart,
                cliente : cliente,
                datos_pedido : datos_pedido,
                subtotal: subtotal
            }
            
            const url = 'https://vps-1873858-x.dattaweb.com/testing/dorla/api/v1/confirmarCompra';
            
            axios
                .post(url,data)
                .then((response) => {
                    console.log(response)
                    dispatch(setEnviado())
                    dispatch(clearCart())
                    window.location.href = "/confirmar"
                })
                .catch((error) => console.log(error))
        }
    }
}
    

export default PedidoConfirmado