import React from 'react';
import Carousel from 'react-bootstrap/Carousel';


function Slider(props){
    return (
        <Carousel variant="dark" style={{height: '90vh'}}>
      <Carousel.Item  style={{height: '90vh'}}>
        <img
          className="d-block w-100"
          src="https://images.unsplash.com/photo-1498747946579-bde604cb8f44?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1032&q=80"
          alt="First slide"
        />
        <Carousel.Caption className="d-none d-lg-block">
          <h3>PRODUCTOS PARA PILETAS</h3>
          <p>Bombas, Filtros, Pastillas y más.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item style={{height: '90vh'}}>
        <img
          className="d-block w-100"
          src="https://images.unsplash.com/photo-1563453392212-326f5e854473?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
          alt="Second slide"
        />

        <Carousel.Caption  className="d-none d-lg-block">
          <h3>PRODUCTOS DE LIMPIEZA</h3>
          <p>Precios diréctos de fábrica </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item style={{height: '85vh'}}>
        <img
          className="d-block w-100"
          src="https://images.unsplash.com/photo-1445510491599-c391e8046a68?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
          alt="Third slide"
        />

        <Carousel.Caption  className="d-none d-lg-block">
          <h3>Línea FLOW</h3>
          <p>
            Perfuminas y Detergentes de fabricación propia.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>

    )
}

export default Slider;