
import PedidoConfirmado from "../components/PedidoConfirmado";
import OffCanvasExample from "../components/OffCanvas";
import Footer from "../components/Footer";
function Confirmar(){
    
    return (
        <div>
            <OffCanvasExample />
            <PedidoConfirmado />
            <Footer />
        </div>
    );
}

export default Confirmar;