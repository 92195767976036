import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert'; // Import


export const cartSlice = createSlice({
    
    name: 'items',
    initialState: {
        list: [],
        cart: [],
        datos_pedido : [],
        subtotal: 0,
        isLoading: true,
        isAdding: false,
        isSearch: false,
        isLoaded: false,
        enviado: false,
        categorias : [],
        artCategorias : [],
        props : {
            index: 0,
            desc : '',
            limit : 100,
            categoria: 0,
            scroll: 0
        },
        cliente: {
            id: 0,
            nombre: "",
            opciones_pago: ""
        },
        status : {
            error: false,
            msg: ""
        }
    },
    reducers : {
        setCarrito: (state,action) =>{
            console.log(action.payload)
            state.cart = action.payload.cart;
            state.datos_pedido = action.payload.datos_pedido
            state.subtotal = action.payload.subtotal;
            state.isLoaded = true;
            state.enviado = action.payload.enviado;
            localStorage.setItem('carrito', JSON.stringify(state));
        },
        setProducts: (state,action) => {
            //console.log(action.payload);
            state.list = action.payload;
        },
        addProduct: (state,action) => {
            if(state.cart.length === 0){
                state.enviado = false
            }
            //console.log(action.payload)
            const aux = state.cart.findIndex((x) => x.id === action.payload.post.id);
            if(aux < 0){
                const item = {
                    id : action.payload.post.id,
                    q : action.payload.cantidad,
                    cod_barras:action.payload.post.cod_barras,
                    descripcion:action.payload.post.descripcion,
                    descuento:action.payload.post.descuento,
                    imagen:action.payload.post.imagen,
                    precio:action.payload.post.precio
                }
                //item["q"] = 1;
                state.cart = state.cart.concat(item)
                if(action.payload.cantidad > 0){
                    //state.cart[0].q += Number(action.payload.cantidad-1)
                }
            }
            else{
                state.cart[aux].q = Number(state.cart[aux].q) + (Number(action.payload.cantidad) || 1);
            }
            var subtotal = 0
            state.cart.forEach(function(item){
                subtotal = subtotal + (item.q*item.precio)
            })
            state.subtotal = subtotal

            localStorage.setItem('carrito', JSON.stringify(state));

        },
        removeProduct: (state,action) => {
            console.log("remove: "+action.payload)
            const index = state.cart.findIndex((x) => x.id === action.payload);
            const array = state.cart; // make a separate copy of the array
            if (index !== -1) {
                array.splice(index, 1);
                console.log(array)
                state.cart = array;
            }
            var subtotal = 0
            state.cart.forEach(function(item){
                subtotal = subtotal + (item.q*item.precio)
            })
            state.subtotal = subtotal

            localStorage.setItem('carrito', JSON.stringify(state));
        },
        clearCart: (state) => {
            console.log("clear cart")
            state.cart = []
            var subtotal = 0
            /*
            state.cart.forEach(function(item){
                subtotal = subtotal + (item.q*item.precio)
            })
            */
            state.subtotal = subtotal
            localStorage.setItem('carrito', JSON.stringify(state));

        },
        setStatus : (state,action) => {
            console.log(action)
            state.status = action.payload
        },
        addMoreProducts: (state,action) => {
            //state.index = state.index + 20

           /* 
            action.payload.forEach(function(item){
                const found = state.list.indexOf(item)
                if(found < 0){
                    state.list = state.list.concat(item)
                }
            })
            */
            state.list = state.list.concat(action.payload)
        },
        setIsLoading(state,action){
            state.isLoading = action.payload;
        },
        setIsAdding(state,action){
            state.isAdding = action.payload;
        },
        setIsSearch(state,action){
            state.isSearch = action.payload;
        },
        setCategorias(state,action){
            state.categorias = action.payload;
        },
        setIndex(state,action){
            state.props.index = action.payload
        },
        setProps(state,action){
            state.props = action.payload
        },
        setArticulosCategorias(state,action){
            state.artCategorias = action.payload
        },
        setCliente(state,action){
            if(action.payload !== undefined){
                state.cliente = action.payload
            }
            if(state.cliente.id !== 0){
                state.cliente.opciones_pago = [
                    {
                        value : "mercado_pago",
                        name : "Mercado Pago"
                    },
                    {
                        value : "transferencia",
                        name : "Transferencia"
                    },
                    {
                        value : "efectivo",
                        name : "Efectivo"
                    },
                    {
                        value : "cuenta_corriente",
                        name : "Cuenta Corriente"
                    }
                ]
            }
            else{
                state.cliente.opciones_pago = [{
                    value : "mercado_pago",
                    name : "Mercado Pago"
                }]
            }
            localStorage.setItem('cliente', JSON.stringify(state.cliente));
            state.isLoaded = true;
        },
        setDatosPedido(state,action){
            console.log(action.payload)
            state.datosPedido = action.payload
            console.log(state.datosPedido)
        },
        setEnviado(state){
            state.enviado = true
        }
    }
});




export const {setProducts,addProduct,removeProduct,clearCart,setIsLoading,setCategorias,
    setIndex,getIndex, addMoreProducts, setIsAdding, setProps, setIsSearch, setArticulosCategorias,
    setCarrito,setCliente,setDatosPedido,setEnviado,setStatus} = cartSlice.actions;
export default cartSlice.reducer;
export const getAllProducts = () => (dispatch) => {
    dispatch(setIsLoading(true));
    axios
    .get(process.env.REACT_APP_BASE_URL+'/dorla/api/v1/getArticulosWeb?index=0&limit=100&categoria=%22%22')
    .then((response) => {
        //console.log(response)
        
        dispatch(setProducts(response.data))
        dispatch(setIsLoading(false));
    })
    .catch((error) => console.log(error))
}

export const searchProducts = (props) => (dispatch) => {
    //console.log(props)
    if(props.args.desc !== ""){
        dispatch(setIsLoading(true));
        const sparams = {"params" : props.args}
        const url = process.env.REACT_APP_BASE_URL+'/dorla/api/v1/getArticulosWeb'
        axios
        .get(url,sparams)
        .then((response) => {
            //console.log(response)
            if(response.data.length === 0){
                confirmAlert({
                    message: "No se encontraron artículos para el texto indicado",
                    buttons: [
                      {
                        label: 'Aceptar',
                        onClick: () => {}
                      }
                    ],
                    overlayClassName : "custom-alert"
                  })
            }
            dispatch(setProps(props.args))
            dispatch(setIsLoading(false));
            dispatch(setProducts(response.data))
            dispatch(setIsLoading(false));
        })
        .catch((error) => console.log(error))
    }
    else{
        console.log("indique una descripcion")
    }
}

export const getCategorias = () => (dispatch) => {
    const url = process.env.REACT_APP_BASE_URL+'/dorla/api/v1/getCategorias'
    axios
    .get(url)
    .then((response) => {
        console.log(response)
        dispatch(setCategorias(response.data))
    })
    .catch((error) => console.log(error))
}
export const getArticulosCategorias = () => (dispatch) => {
    const url = process.env.REACT_APP_BASE_URL+'/dorla/api/v1/getArticulosCategorias'
    axios
    .get(url)
    .then((response) => {
        console.log(response)
        dispatch(setArticulosCategorias(response.data))
    })
    .catch((error) => console.log(error))
}
/*
export const loadMoreProducts = (props) => (dispatch) => {
    console.log(props)
    console.log("LOAD MORE")
    dispatch(setIsAdding(true));
    var index = props.index + 20;
        var args = {
            index: index,
            desc : props.desc,
            limit : 20,
            categoria : 0
        }
    //var index = props.index + 20;
        dispatch(setProps(args));
    //dispatch(setProps(props));

    console.log(props)
    const url = process.env.REACT_APP_BASE_URL+'/dorla/api/v1/getArticulosWeb'
    const sparams = {"params" : args}
    axios
    .get(url,sparams)
    .then((response) => {
        console.log(props)
        
        dispatch(addMoreProducts(response.data));
        dispatch(setIsAdding(false));
    })
    .catch((error) => console.log(error))
}
*/


export function loadMoreProducts(params) {  
    //console.log(params)

    //console.log(args)
    return async dispatch => {
        var args = {
            index: params.index+20,
            desc : params.desc,
            limit : 20,
            categoria : 0
        }
      function onSuccess(success) {
        dispatch(setProps(args));
        //console.log(args)
        //dispatch(addMoreProducts(success.data));
        
        if(success.data.length > 0){
            dispatch(addMoreProducts(success.data));
        }
        
        return success;
      }
      function onError(error) {
          console.log(error)
        //dispatch({ type: ERROR_GENERATED, error });
        return error;
      }
      try {
        const url = process.env.REACT_APP_BASE_URL+'/dorla/api/v1/getArticulosWeb'
        const sparams = {"params" : args}
        //console.log(url)
        //console.log(sparams)
        dispatch(setIsAdding(true));
        axios.get(url, sparams).then((response) => {
            console.log(response);
            dispatch(addMoreProducts(response.data));
            dispatch(setIsAdding(false));
        })
        /*
        const success = await axios.get(url, sparams);
        dispatch(setIsAdding(false));
        return onSuccess(success);
        */
      } catch (error) {
        return onError(error);
      }
    }
  }