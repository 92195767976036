import React from 'react';
import NavBar from '../components/NavBar';
import Slider from '../components/Slider';
import OffcanvasExample from '../components/OffCanvas';
import { useSelector } from 'react-redux';
import Productos from '../components/Productos';
import Footer from '../components/Footer';
import Nosotros from '../components/Nosotros';



function Home(props){
    const {list} = useSelector(state => state.cartSlice);
    const {isSearch} = useSelector(state => state.cartSlice);

    return (
        <div>
            <OffcanvasExample />
            {
            list.length > 0 && isSearch?<Productos />:
                <div>
                <Slider />
                </div>
            }
            <Footer />
        </div>
    );
}

export default Home;