import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Products from "./pages/Products";
import Confirmar from "./pages/Confirmar";
import PedidoConfirmado from "./pages/PedidoConfirmado";
import "./App.css";

function App(props) {
  return (
    <BrowserRouter>
      <Routes>

        <Route path="/" caseSensitive={false} element={<Home/>} />
          <Route path="/products" caseSensitive={false} element={<Products/>} />
          <Route path="/confirmar" caseSensitive={false} element={<Confirmar/>} />
          <Route path="/pedido_confirmado" caseSensitive={false} element={<PedidoConfirmado/>} />
      </Routes>
    </BrowserRouter>
  );
};
export default App;
