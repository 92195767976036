import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {Provider} from 'react-redux';
import store from './store'


const container = document.getElementById('root');

// Create a root.
const root = ReactDOM.createRoot(container);

// Initial render
root.render(

    <Provider store={store}>
        <App />
    </Provider>

);

// During an update, there is no need to pass the container again
//root.render(<App name="Saeloun testimonials" />);

