import { useDispatch, useSelector } from 'react-redux'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {clearCart, removeProduct, searchProducts, setProps, setCarrito} from '../store/cart'



function Carrito(){
  const {cart} = useSelector(state => state.cartSlice);
  const dispatch = useDispatch()
  const logo = require('../assets/img/logo.png'); // with require
  const cliente = JSON.parse(localStorage.getItem("cliente"));
  console.log(cart)
  /*
    const carrito = JSON.parse(localStorage.getItem("carrito"))
    
    dispatch(setCarrito(carrito))
    */
    return(
        cart.map(item =>
            <Row style={{ height: '90px' }} >
              <Col xs={3} md={3} xl={3}>
                <img width="60" src={item.imagen !== ''?`data:image/jpeg;base64,${item.imagen }`:logo} alt={item.id}/>
              </Col>
              <Col xs={8} md={8} xl={8}>
                <Row  className="text-secondary">{item.descripcion}</Row>
                <Row >
                  <Col>{item.q} x {(cliente.lista == 2 && item.precio2 > 0) ? item.precio2 : item.precio}
                  </Col>
                  <Col className="text-end">{(item.q * (cliente.lista == 2 && item.precio2 > 0 ? item.precio2 : item.precio)).toLocaleString('es-AR', { 
                      style           : 'currency',
                      currency        : 'ARS',
                      currencyDisplay : 'symbol',
                      useGrouping     : true
                    })}
                  </Col>
                </Row>
              </Col>
              <Col className="fw-bold" xs={1} md={1} xl={1}><p role="button" onClick={() => dispatch(removeProduct(item.id))}>X</p></Col>
            </Row>
          )
    )
    
}

export default Carrito